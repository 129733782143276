<template>
  <router-link :to="service.to">
    <v-row
      no-gutters
      justify="center"
      align="center"
      class="py-3"
      :class="btnClass"
    >
      <img :src="service.icon" :alt="service.name" class="mr-2" />
      <span v-html="service.name" class="ml-3 black--text text-body-1" />
    </v-row>
  </router-link>
</template>
<style scoped lang="scss">
img {
  width: 60px;
}
.btn_1 img {
  width: 75px; // fix for reduced home delivery image size
}
a {
  text-decoration: none;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .btn_1,
  .btn_2 {
    border-left: 1px solid var(--v-grey-base);
  }
}
</style>
<script>
export default {
  name: "ServiceButton",
  props: {
    service: { type: Object, required: true },
    btnClass: { type: String, required: false }
  }
};
</script>

<style></style>
