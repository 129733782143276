<template>
  <div class="profile">
    <v-container>
      <component
        :is="templateName"
        :key="key"
        :page="page"
        :additionalData="additionalData"
      ></component>
    </v-container>
  </div>
</template>
<script>
import CMSService from "~/service/cmService";
import Static from "@/components/wordpress/Static";
import Service from "@/components/wordpress/Service";

import startCase from "lodash/startCase";
import get from "lodash/get";

export default {
  data() {
    return { page: {}, key: 1, additionalData: null };
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["https://google.com/logo.jpg"],
        author: {
          "@type": "Organization",
          name: "Dupliclick"
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: "Dupliclick",
          logo: {
            "@type": "ImageObject",
            url: "https://google.com/logo.jpg"
          }
        }
      };
    }
  },
  created() {
    if (this.$route.params.additionalData) {
      this.additionalData = this.$route.params.additionalData;
    }
    this.fetchPage(this.$route.params.pageName);
  },
  components: { Static, Service },
  computed: {
    templateName() {
      if (this.page.template) {
        let templateName = startCase(this.page.template);
        return templateName;
      } else {
        return "Static";
      }
    }
  },
  methods: {
    async fetchPage(pageName) {
      let data = await CMSService.getPage(pageName);
      if (data.length > 0) {
        this.page = data[0];
        this.key++;
      }
    }
  },
  metaInfo() {
    return {
      title: get(this.page, "meta.seo_title", "Pagina statica"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(this.page, "meta.seo_title", "DupliClick")
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: "DupliClick"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          name: "twitter:image",
          content: "https://" + window.location.host + "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  },
  watch: {
    "$route.params.pageName": function(pageName) {
      this.fetchPage(pageName);
    }
  }
};
</script>
