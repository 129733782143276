<template>
  <v-container @click="clicked">
    <!-- <h1 v-html="title"></h1> -->
    <v-runtime-template :template="template" :additionalData="additionalData" />
  </v-container>
</template>
<script>
import toNumber from "lodash/toNumber";
import VRuntimeTemplate from "v-runtime-template";
import ServiceAutodiscovery from "@/components/buttons/ServiceAutodiscovery";
import ServiceCoverage from "@/components/buttons/ServiceCoverage";
import ServiceButtons from "@/components/buttons/ServiceButtons";
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";
import ProductListSlider from "@/components/wordpress/WpProductListSlider.vue";
import ProductActions from "@/components/product/ProductActions.vue";

import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Static",
  mixins: [clickHandler],
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    ServiceAutodiscovery,
    ServiceCoverage,
    ServiceButtons,
    NinjaForm,
    ProductListSlider,
    ProductActions
  },
  props: {
    page: { type: Object, required: true },
    additionalData: { type: Object, required: false }
  },
  computed: {
    deliveryServiceId() {
      return toNumber(this.page.deliveryserviceid);
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  }
};
</script>
