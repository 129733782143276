˘<template>
  <div>
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="(service, idx) in activeButton"
        :key="idx"
        class="my-auto"
      >
        <service-button
          :service="service"
          :btnClass="`service-button btn_${idx}`"
        />
      </swiper-slide>
    </swiper>
    <div class="d-flex justify-end">
      <v-btn
        color="white"
        text
        plain
        :to="{ name: 'Page', params: { pageName: 'i-servizi-dupliclick' } }"
      >
        <i18n
          tag="span"
          class="primary--text text-caption font-weight-bold text-none"
          v-html="$t('services.discoverLink')"
        />
        <v-icon class="primary white--text rounded-lg ml-3">$next</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ServiceButton from "./ServiceButton";
export default {
  name: "ServiceButtons",
  props: {
    disabledButtons: {
      type: Array,
      required: false,
      default: function() {
        return new Array(0);
      }
    }
  },
  components: { ServiceButton },
  data() {
    return {
      buttons: [
        {
          deliveryServiceId: 1,
          page: "service-discovery",
          icon: "/img_layout/services-icons/ritiro-in-negozio.svg",
          name: "Ritiro in<br/><b>NEGOZIO</b>",
          to: { name: "Page", params: { pageName: "i-servizi-dupliclick" } }
        },
        {
          deliveryServiceId: 2,
          page: "service-discovery",
          icon: "/img_layout/services-icons/consegna-in-azienda.svg",
          name: "Consegna in <br/><b>AZIENDA</b>",
          to: { name: "Page", params: { pageName: "i-servizi-dupliclick" } }
        }
      ],
      swiperOption: {
        spaceBetween: 20,
        watchOverflow: true,
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          960: {
            slidesPerView: 2
          }
        }
      }
    };
  },
  computed: {
    cols() {
      return 12 / (this.buttons.length - this.disabledButtons.length);
    },
    activeButton() {
      var result = [];

      this.buttons.forEach(element => {
        if (!this.disabledButtons.includes(element.deliveryServiceId)) {
          result.push(element);
        }
      });
      return result;
    }
  }
};
</script>
